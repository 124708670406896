jQuery(function ($) {

  // cache elements
  var $body = $('body'),
      $nav = $('#site-navigation');

  // Split up the sub-menu
  $('#menu-main .sub-menu').each(function() {
    var items = $(this).children();
    var divider = (Math.floor(items.length / 2));
    if (divider) {
      // console.log(items.length + ' : ' + divider);
      for (
        var i= divider;
        i<items.length;
        i+= (items.length - divider)
      ) {
        items.slice(i, i+divider).appendTo( $('<ul class="sub-menu">').insertAfter($(this)) );
      }
    }
  });

  // Set up mobile menu
  $nav.prepend('<button class="menu-trigger">Open Menu</button>');
  $body.append('<div class="mobile-overlay"></div>');
  $('.menu-trigger').on('click', function () {
    $body.addClass('mobile-open mobile-opened');
  });

  // close menu when overlay clicked
  $('.mobile-overlay').on('click', function () {
    $body.removeClass('mobile-open');
  });

  // Add drop menu touch events
  $nav.find('.menu-item-has-children >a').on('touchstart', function(){
    var $this = $(this),
        href= $this.attr('href');

    // Go to the link if there is one
    if ( (href !== '#') && $this.parent().hasClass('touch-opened') ) {
      document.location = href;
      return true;
    }
    $this.parent().addClass('touch-opened');
    return false;
  });

});
