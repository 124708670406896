/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(".main a[href*='//']")
          .not("[href*='paddleboston.com']")
          .attr("target","_blank");
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('.classBox').append('<a id="cancellation-policy-link" href="#cancellation-policy">Read Cancellation Policy</a><div id="cancellation-policy" class="mfp-hide"><h1>Cancellation Policy</h1><ul><li>Full payment is due upon registration for a class, clinic, or trip.</li><li>For cancellations received 7 days or more before your scheduled activity, we will issue a refund less a 10% registration fee.</li><li>For cancellations received fewer than 7 days before your scheduled activity, we are unable to offer any refunds.</li><li>Classes, clinics, and trips always run unless the weather is extreme.</li><li>A minimum number of registrants is required for any event to run. If we cancel an event due to low enrollment, we will provide a full refund.</li><li>Each participant will be required to sign a waiver of liability. To save time, you can download this waiver and bring it with you.</li></ul><p><a class="popup-modal-dismiss mfp-close" href="#">x</a></p></div>');

        $('#cancellation-policy-link').magnificPopup({
            type: 'inline',
            preloader: false,
            modal: true
          });
          $(document).on('click', '.popup-modal-dismiss', function (e) {
            e.preventDefault();
            $.magnificPopup.close();
          });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // 
    'rentals': {
      init: function() {
        $('.accordion-tabs').each(function(index) {
          $(this).children('li').first().children('a').addClass('is-active').next().addClass('is-open').css('display', 'block');
        });
        $('.accordion-tabs').on('click', 'li > a.tab-link', function(event) {
          if (!$(this).hasClass('is-active')) {
            event.preventDefault();
            var accordionTabs = $(this).closest('.accordion-tabs');
            accordionTabs.find('.is-open').removeClass('is-open').hide();

            $(this).next().toggleClass('is-open').fadeToggle("slow");
            accordionTabs.find('.is-active').removeClass('is-active');
            $(this).addClass('is-active');
          } else {
            event.preventDefault();
          }
        });
        $('.accordion-arrows').on('click', 'a', function() {
          var accordionTabs = $('.accordion-tabs');
          var current = accordionTabs.find('.is-active');
          var direction = ($(this).hasClass('tab-left')) ? -1 : 1;

          var tabs = accordionTabs.find('li');
          var currentIndex = '';
          tabs.each(function(i) {
            if ($(this).find('.tab-link').hasClass('is-active')) {
              currentIndex = i;
            }
          });


          var nextIndex = currentIndex + direction;
              nextIndex = (nextIndex < 0) ? (tabs.length - 1) : nextIndex; // move to the end
              nextIndex = (nextIndex >= tabs.length) ? 0 : nextIndex; // Move to the front

          // Hide the current tab
          current.removeClass('is-active');
          current.next().toggleClass('is-open').fadeToggle("slow");

          // Show the next tab
          $(tabs[nextIndex]).find('.tab-link').addClass('is-active')
                            .next().toggleClass('is-open').fadeToggle("slow");
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $('.execphpwidget:empty').parents('.dslc-widget').remove();
  // if ( $.trim( $('.execphpwidget').text() ) === "") {
  //   $('.execphpwidget').html().parents('.dslc-widget').remove();

  // }

})(jQuery); // Fully reference jQuery after this point.
